<template>
	<div>
		<CRow>
			<CCol lg="12" class="d-flex">
				<h4 class="mb-4 general-info">
					General info
				</h4>

				<div class="banner-id">
					Campaign ID: <strong>{{ campaignId }}</strong>
				</div>
			</CCol>
		</CRow>
		<CRow v-if="!hasPriority">
			<CCol xl="12">
				<CInput
					v-model.trim="$v.nameValue.$model"
					:is-valid="!$v.nameValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:label="nameLabel"
					type="text"
					data-test-id="input-name"
				/>
			</CCol>
		</CRow>
		<CRow v-else>
			<CCol md="9">
				<CInput
					v-model.trim="$v.nameValue.$model"
					:is-valid="!$v.nameValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					:label="nameLabel"
					type="text"
					data-test-id="input-name"
				/>
			</CCol>
			<CCol md="3">
				<CInput
					v-model.trim="$v.priorityValue.$model"
					:is-valid="!$v.priorityValue.$error && null"
					:invalid-feedback="$t('global.error.required')"
					label="Priority*"
					type="number"
					data-test-id="input-priority"
				/>
			</CCol>
		</CRow>
		<CRow v-if="hasSessionBase" class="mb-4">
			<CCol md="6">
				<BaseInputNumber
					v-model.trim="$v.durationValue.$model"
					:is-valid="!$v.durationValue.$error"
					:invalid-feedback="$t('global.error.required')"
					:disabled="sessionBased"
					label="Re-eligibility duration*"
					placeholder="1"
					append-text="hr"
					text-align="left"
				/>

				<CInputCheckbox
					:checked="sessionBased"
					custom
					class="mt-3"
					label="Session based"
					@update:checked="handleUpdateSessionBased"
				/>
			</CCol>
			<CCol md="6" class="d-flex flex-column justify-content-center gap-1">
				<p class="typo-body-2 color-black-45 mb-0">
					- Campaign re-eligibility Duration after click and re-eligibility Duration after close.
				</p>
				<p class="typo-body-2 color-black-45 mb-0">
					- Session based will last until the browser is closed.
				</p>
			</CCol>
		</CRow>
		<CRow class="mb-5">
			<CCol xl="12" class="d-flex align-items-center">
				<label class="toggle-label" for="display-status">Status</label>
				<CSwitch
					id="display-status"
					:checked="status"
					variant="3d"
					size="sm"
					class="switch-custom toggle-status"
					color="success"
					@update:checked="handleUpdateStatus"
				/>
				<span>{{ statusText }}</span>
			</CCol>
		</CRow>

		<hr>
	</div>
</template>

<script>
import { required, requiredIf } from 'vuelidate/lib/validators';

// using same as banner form
import { STATUS as mappingStatus } from '../mapping/banner';

export default {
	name: 'OnSiteMessageGeneral',

	validations() {
		return {
			nameValue: {
				required,
			},
			durationValue: {
				requiredIf: requiredIf((data) => data.hasSessionBase && data.sessionBased),
			},
			priorityValue: {
				requiredIf: requiredIf((data) => data.hasPriority),
			},
		};
	},

	props: {
		id: {
			type: Number,
			default: 0,
		},
		name: {
			type: String,
			default: '',
		},
		nameLabel: {
			type: String,
			default: '',
		},
		duration: {
			type: [String, Number],
			default: 0,
		},
		priority: {
			type: [String, Number],
			default: 0,
		},
		status: {
			type: Boolean,
			default: false,
		},
		sessionBased: {
			type: Boolean,
			default: false,
		},
		hasSessionBase: {
			type: Boolean,
			default: true,
		},
		hasPriority: {
			type: Boolean,
			default: false,
		},
	},

	computed: {
		campaignId() {
			return this.id ? this.id : this.$t('page.banner.create.generateId');
		},

		nameValue: {
			get() {
				return this.name;
			},
			set(value) {
				this.$emit('update:name', value);
			},
		},

		durationValue: {
			get() {
				return this.duration;
			},
			set(value) {
				this.$emit('update:duration', value);
			},
		},

		priorityValue: {
			get() {
				return this.priority;
			},
			set(value) {
				this.$emit('update:priority', value);
			},
		},

		statusText() {
			const text = mappingStatus[this.status] || '';
			return this.$t(text);
		},
	},

	methods: {
		clearError() {},

		handleUpdateStatus(value) {
			this.$emit('update:status', value);

			this.clearError();
		},

		handleUpdateSessionBased(value) {
			this.$emit('update:sessionBased', value);

			this.clearError();
		},
	},
};
</script>

<style lang="scss" scoped>
	.general-info {
		flex: 1;
	}

	.banner-id {
		font-size: rem(12);
		color: $color-black-45;
	}

	.toggle {
		&-label {
			width: rem(54);
			margin: 0 rem(24) 0 0;
		}

		&-status {
			margin-right: rem(12);
		}
	}
</style>
