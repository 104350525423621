<template>
	<div>
		<CRow class="mt-5">
			<CCol lg="12">
				<h4 class="mb-4">
					<slot name="title"></slot>
				</h4>
				<slot name="description"></slot>
			</CCol>
		</CRow>

		<BannerInfo :desktop-img="desktopImg" :mobile-img="mobileImg">
			<slot name="detail">
				Popup campaign will be displayed on the middle of screen as
				shown. Using the same size for desktop and mobile.
			</slot>
		</BannerInfo>

		<slot></slot>

		<CRow v-if="hasCampaignLink">
			<CCol md="12">
				<CInput
					v-model.trim="$v.campaignLinkValue.$model"
					:is-valid="isValidCampaignLink"
					:invalid-feedback="campaignLinkInvalidFormatText"
					:description="campaignLinkHelperText"
					:label="`Campaign Link${requiredLink ? '*': ''}`"
				/>
			</CCol>
		</CRow>

		<hr>
	</div>
</template>

<script>
import BannerInfo from '@/components/BannerInfo.vue';

import { requiredIf, url } from 'vuelidate/lib/validators';
import { pathOr } from '../assets/js/helpers';

export default {
	name: 'OnSiteMessageContentWrapper',

	components: {
		BannerInfo,
	},

	validations() {
		return {
			campaignLinkValue: {
				// user must selected any banner image(s)
				// and the value on banner link input must not be empty
				required: requiredIf((data) => data.requiredLink),
				// check invalid url format
				url,
			},
		};
	},

	props: {
		desktopImg: {
			type: String,
			default: '',
		},
		mobileImg: {
			type: String,
			default: '',
		},
		requiredLink: {
			type: Boolean,
			default: false,
		},
		campaignLink: {
			type: String,
			default: '',
		},
		hasCampaignLink: {
			type: Boolean,
			default: true,
		},
	},

	computed: {
		campaignLinkValue: {
			get() {
				return this.campaignLink;
			},
			set(value) {
				this.$emit('update:campaignLink', value);
			},
		},

		isValidCampaignLink() {
			const isError = pathOr(false, ['$v', 'campaignLinkValue', '$error'])(this);
			return isError ? false : null;
		},

		campaignLinkInvalidFormatText() {
			if (this.isValidCampaignLink === false) {
				return !this.campaignLink ? this.$t('global.error.required') : this.$t('page.banner.create.linkBannerInvalidFormat');
			}

			return null;
		},

		campaignLinkHelperText() {
			return this.isValidCampaignLink === null
				? this.$t('page.banner.create.linkBannerHelperText')
				: null;
		},
	},
};
</script>
